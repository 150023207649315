import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    fullName: null,
    loggedIn: false,
    token: null
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  mutations: {
    login(state, data) {
      state.loggedIn = true;
      state.fullName = data.full_name;
      state.token = data.access_token;
    },
    logout(state) {
      state.fullName = null;
      state.loggedIn = false;
      state.token = null;
    }
  },
  actions: {},
  modules: {},
});
