<template>
  <div class="footer-container">
    <div class="footer">
      <div class="top-row">
        <div class="column">
          <h3>{{ $t("footer.help") }}</h3>
          <ul>
            <li>
              <a
                href="https://www.zealid.com/en/coverage"
                rel=" noopener"
                target="_blank"
                >{{ $t("footer.guide") }}</a
              >
            </li>
            <li>
              <a
                href="https://www.zealid.com/en/consumers#frequently-asked-questions"
                rel=" noopener"
                target="_blank"
                >{{ $t("footer.faq") }}</a
              >
            </li>
            <li>
              <a
                href="https://www.zealid.com/en/consumers"
                rel=" noopener"
                target="_blank"
                >{{ $t("footer.consumer") }}</a
              >
            </li>
            <li>
              <a
                href="https://www.zealid.com/en/repository"
                rel=" noopener"
                target="_blank"
                >{{ $t("footer.repository") }}</a
              >
            </li>
          </ul>
        </div>
        <div class="column">
          <h3>{{ $t("footer.contact") }}</h3>
          <ul>
            <li>Jakobsbergsgatan 16</li>
            <li>111 44 {{ $t("footer.stockholm") }}</li>
            <li>{{ $t("footer.sweden") }}</li>
          </ul>
        </div>
        <div class="column zeal">
          <a href="//www.zealid.com" target="_blank"
            ><img src="@/assets/logos/zealid-logo-white.webp" alt="ZealiD"
          /></a>
          <p>
              2021 ZealiD AB -
              <a href="https://www.zealid.com/privacy?hsLang=en" target="_blank">{{ $t("footer.privacy_and_cookies") }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-container {
  background-color: #343434;
}
.footer {
  font-size: 0.89rem;
  line-height: 2;
  font-weight: 600;
  padding: 47px 63px 72px;
  margin: auto;
  max-width: 1168px;
  @media (max-width: 1024px) {
    padding: 47px 27px 72px;
  }
}
.top-row {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 16px;
  .column {
    margin-right: 23px;
  }
  h3 {
    margin-bottom: 6px;
    margin-top: 25px;
    color: #eea557;
    line-height: 1.38;
    font-weight: 600;
    text-align: left;
    font-size: 16px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  li {
    color: #c7c7c7;
  }
  a {
    text-decoration: none;
    color: #c7c7c7;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}
.zeal {
  margin-top: 25px;
  p {
      margin: 0;
      font-size: 10px;
      font-weight: 800;
      color: #fff;
      text-transform: none;
      a {
          color: #fff;
          text-decoration: none;
      }
  }
}
</style>

<script>
export default {

}
</script>
