<template>
  <modal name="idle" :clickToClose="false" :adaptive="true" :height="auto">
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">
        {{ $t("modal.session_timeout") }}
      </div>
      <div>{{ $t("modal.session_timeout_text", { seconds: time }) }}</div>
    </div>
    <div class="vue-dialog-buttons">
      <button class="vue-dialog-button" @click="continueSession()">
        {{ $t("modal.continue") }}</button
      ><button class="vue-dialog-button" @click="logout()">
        {{ $t("menu.logout") }}
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      time: 30,
      isInactive: true,
      timerId: null,
    };
  },
  methods: {
    continueSession() {
      clearInterval(this.timerId);
      this.isInactive = false;
      this.$modal.hide("idle");
      this.$emit("idle-modal-closed", true);
      this.$http.get("/session").catch(() => {
        this.$store.commit("logout");
        this.$router.push({
          name: "Login",
          params: { locale: this.$i18n.locale },
        });
      });
    },
    logout() {
      this.$http.get("/logout").finally(() => {
        this.$store.commit("logout");
        this.$router.push({
          name: "Login",
          params: { locale: this.$i18n.locale },
        });
        location.reload();
      });
    },
  },
  created() {
    this.timerId = setInterval(() => {
      this.time -= 1;
      if (this.time < 1) {
        clearInterval(this.timerId);
        this.logout();
      }
      if (!this.isInactive) {
        clearInterval(this.timerId);
      }
    }, 1000);
  },
  mounted() {
    this.$modal.show("idle");
  },
};
</script>

<style></style>
