import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import api from "./axios";
import VModal from "vue-js-modal";
import IdleVue from "idle-vue";
import VTooltip from "v-tooltip";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Notifications from "vue-notification";

Vue.prototype.$http = api;

const moment = require("moment");

Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(VModal, { dialog: true });

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 570000,
  startAtIdle: false,
  events: [
    "mousemove",
    "keydown",
    "mousedown",
    "touchstart",
    "unload",
    "message",
  ],
});

Vue.use(VTooltip, {
  defaultTrigger: "hover focus click",
});

Vue.component("loading", Loading);
Vue.use(Notifications);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
