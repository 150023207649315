import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../i18n";
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import store from "@/store";

Vue.use(VueRouter);

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

const routes = [
  {
    path: "/:locale",
    component: {
      template: "<router-view></router-view>",
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale;
      const supportedLocales = getSupportedLocales().map(
        (locale) => locale.code
      );
      if (!supportedLocales.includes(locale)) {
        return next(i18n.locale);
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale;
      }
      return next();
    },
    children: [
      {
        path: "",
        name: "Login",
        component: load("Login"),
        beforeEnter(from, to, next) {
          if (store.state.loggedIn) {
            next(`/${i18n.locale}/home`);
          } else {
            next();
          }
        },
      },
      {
        path: "home",
        name: "Home",
        component: load("Home"),
        beforeEnter(from, to, next) {
          if (!store.state.loggedIn) {
            next(`/${i18n.locale}`);
          } else {
            next();
          }
        },
      },
      {
        path: "my-info",
        name: "MyInfo",
        component: load("MyInfo"),
        beforeEnter(from, to, next) {
          if (!store.state.loggedIn) {
            next(`/${i18n.locale}`);
          } else {
            next();
          }
        },
      },
      {
        path: "upload",
        name: "Upload",
        component: load("Upload"),
        beforeEnter(from, to, next) {
          if (!store.state.loggedIn) {
            next(`/${i18n.locale}`);
          } else {
            next();
          }
        },
      },
      {
        path: "documents/:id?",
        name: "Documents",
        component: load("Documents"),
        beforeEnter(from, to, next) {
          if (!store.state.loggedIn) {
            next(`/${i18n.locale}`);
          } else {
            next();
          }
        },
      },
      {
        path: "certificates",
        name: "Certificates",
        component: load("Certificates"),
        beforeEnter(from, to, next) {
          if (!store.state.loggedIn) {
            next(`/${i18n.locale}`);
          } else {
            next();
          }
        },
      },
      {
        path: "transactions",
        name: "Transactions",
        component: load("Transactions"),
        beforeEnter(from, to, next) {
          if (!store.state.loggedIn) {
            next(`/${i18n.locale}`);
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "*",
    redirect() {
      return i18n.locale;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { y: 0, behavior: 'smooth' }
  }
});

export default router;
