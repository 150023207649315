<template>
  <div>
    <div class="collapsed" v-on:click="expand">
      <img class="user-icon" src="@/assets/icons/zeal-male.png" />
    </div>
    <transition name="slide">
      <div v-if="isExpanded" v-click-outside="collapse" class="sidenav-wrp">
        <div class="user-wrp">
          <img
            v-on:click="collapse"
            class="user-icon"
            src="@/assets/icons/zeal-male.png"
          />
          <div class="name-box" :style="{ 'font-size': nameFontSize }">
            {{ state.fullName }}
          </div>
        </div>
        <div class="link-container">
          <router-link :to="{ name: 'MyInfo', params: { locale: i18n.locale } }"
            ><div v-on:click="collapse" class="row">
              {{ $t("menu.my_info") }}
            </div></router-link
          >
          <div class="divider"></div>
          <router-link :to="{ name: 'Upload', params: { locale: i18n.locale } }"
            ><div v-on:click="collapse" class="row">
              {{ $t("menu.upload_and_sign") }}
            </div></router-link
          >
          <div class="divider"></div>
          <router-link
            :to="{ name: 'Documents', params: { locale: i18n.locale } }"
            ><div v-on:click="collapse" class="row">
              {{ $t("menu.documents") }}
            </div></router-link
          >
          <div class="divider"></div>
          <router-link
            :to="{ name: 'Certificates', params: { locale: i18n.locale } }"
            ><div v-on:click="collapse" class="row">
              {{ $t("menu.certificates") }}
            </div></router-link
          >
          <div class="divider"></div>
          <router-link
            :to="{ name: 'Transactions', params: { locale: i18n.locale } }"
            ><div v-on:click="collapse" class="row">
              {{ $t("menu.transactions") }}
            </div></router-link
          >
        </div>
        <div class="bottom-wrp">
          <div class="logout" v-on:click="logout()">
            <div>{{ $t("menu.logout") }}</div>
            <img class="logout-icon" src="@/assets/icons/logout.png" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import store from "@/store";
import i18n from "@/i18n";
import router from "@/router";
import vClickOutside from "v-click-outside";

export default {
  data() {
    return {
      state: store.state,
      i18n: i18n,
      isExpanded: false,
      nameFontSize: "1em",
    };
  },
  methods: {
    logout() {
      this.$http.get("/logout").finally(() => {
        store.commit("logout");
        router.push({ name: "Login", params: { locale: i18n.locale } });
        location.reload();
      });
    },
    expand() {
      this.isExpanded = true;
    },
    collapse() {
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    const nameArr = this.$store.state.fullName.split(" ");
    nameArr.forEach((str) => {
      if (str.length > 13) {
        this.nameFontSize = `${(1 / str.length) * 13}em`;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.collapsed {
  background: linear-gradient(0deg, #6a2545 0%, #b03b6f 100%);
  position: fixed;
  top: 5px;
  right: 0;
  z-index: 3;
  border-radius: 12px;
  margin: 0 -12px 0 0;
  img {
    padding: 8px 7px 5px 12px;
  }
}
.sidenav-wrp {
  width: 197px;
  margin: 0 -12px 0 0;
  border-radius: 12px;
  background: linear-gradient(0deg, #6a2545 0%, #b03b6f 100%);
  position: fixed;
  top: 5px;
  right: 0;
  z-index: 3;
  padding-right: 12px;
}
.user-wrp {
  display: flex;
  align-items: center;
  margin: 12px 12px 0;
}
.user-icon {
  width: 46px;
  height: 46px;
  margin-right: 12px;
  cursor: pointer;
}
.name-box {
  font-weight: 800;
  color: #fff;
  text-align: left;
  width: 120px;
  overflow: hidden;
}
.link-container {
  width: 221px;
  padding: 6px 0;
  background-color: #fff;
  border-radius: 12px;
  margin-left: -12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.19);
  margin-top: 17px;
  .row {
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: #4a4a4a;
    opacity: 0.8;
    margin: 0 24px 0 12px;
    font-weight: 600;
  }
  .row:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
  .router-link-active {
    .row {
      color: #b03b6f;
      font-weight: 900;
    }
  }
  .divider {
    opacity: 0.1;
    border: solid 1px #000000;
    margin: 6px 24px 6px 12px;
  }
}
a {
  text-decoration: none;
}
.logout {
  display: flex;
  justify-content: center;
  margin: 21px 53px 21px 65px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  color: #eea757;
  .logout-icon {
    width: 19px;
    height: 19px;
    margin: 0 0 0 12px;
  }
}
@media (max-height: 440px) {
  .sidenav-wrp {
    font-size: 0.9em;
  }
  .link-container {
    margin-top: 5px;
    .row {
      height: 30px;
    }
    .divider {
      margin: 5px 24px 5px 12px;
    }
  }
  .bottom-wrp {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    .logout {
      margin: 8px 53px 8px 65px;
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: right 0.5s;
}
.slide-enter,
.slide-leave-to {
  right: -220px;
}
</style>
