import axios from "axios";
import store from "./store";
import i18n from "./i18n";
import Vue from "vue";
import router from "./router";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use((config) => {
  if (store.state.token) {
    config.headers["Authorization"] = `Bearer ${store.state.token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    Vue.notify({
      clean: true,
    });
    return response;
  },
  (error) => {
    if (error.response.data.error) {
      Vue.notify({
        type: "warn",
        duration: -1,
        position: "top center",
        max: 1,
        ignoreDuplicates: true,
        text: error.response.data.error,
      });
    }
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          store.commit("logout");
          router.push({
            name: "Login",
            params: { locale: i18n.locale },
          });
          break;
        case 413:
          Vue.notify({
            type: "warn",
            duration: -1,
            position: "top center",
            max: 1,
            ignoreDuplicates: true,
            text: this.$t("errors.payload_too_large"),
          });
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

export default api;
