<template>
  <div class="header-container">
    <div class="header">
      <router-link :to="{ path: '/', params: { locale: $i18n.locale } }"
        ><img src="@/assets/logos/zealid-logo.svg" alt="ZealiD"
      /></router-link>
        <!-- <div class="locale-switcher">
          <LocaleSwitcher />
        </div> -->
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-container {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 2;
}
.header {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 104px;
  max-width: 1168px;
  padding: 0 63px;
  img {
    height: 59px;
  }
  @media (max-width: 1024px) {
    padding: 0 27px;
  }
  @media (max-width: 480px) {
    padding: 0 16px;
  }
  @media (max-width: 768px) {
    height: 81px;
    img {
      height: 42px;
    }
  }
  @media (max-height: 609px) {
    height: 81px;
    padding: 0 48px;
    img {
      height: 42px;
    }
  }
}
</style>

<script>
//import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default {
  components: {
    //LocaleSwitcher,
  },
};
</script>
