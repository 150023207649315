<template>
  <div class="sidenav-wrp">
    <img class="user-icon" src="@/assets/icons/zeal-male.png" />
    <div class="name-box" :style="{ 'font-size': nameFontSize }">
      {{ state.fullName }}
    </div>
    <div class="link-container">
      <router-link :to="{ name: 'MyInfo', params: { locale: i18n.locale } }"
        ><div class="row">{{ $t("menu.my_info") }}</div></router-link
      >
      <div class="divider"></div>
      <router-link :to="{ name: 'Upload', params: { locale: i18n.locale } }"
        ><div class="row">{{ $t("menu.upload_and_sign") }}</div></router-link
      >
      <div class="divider"></div>
      <router-link :to="{ name: 'Documents', params: { locale: i18n.locale } }"
        ><div class="row">{{ $t("menu.documents") }}</div></router-link
      >
      <div class="divider"></div>
      <router-link
        :to="{ name: 'Certificates', params: { locale: i18n.locale } }"
        ><div class="row">{{ $t("menu.certificates") }}</div></router-link
      >
      <div class="divider"></div>
      <router-link
        :to="{ name: 'Transactions', params: { locale: i18n.locale } }"
        ><div class="row">{{ $t("menu.transactions") }}</div></router-link
      >
    </div>
    <div class="logout" v-on:click="logout()">
      <div>{{ $t("menu.logout") }}</div>
      <img class="logout-icon" src="@/assets/icons/logout.png" />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import i18n from "@/i18n";
import router from "@/router";
export default {
  data() {
    return {
      state: store.state,
      i18n: i18n,
      nameFontSize: "1em",
    };
  },
  methods: {
    logout() {
      this.$http.get("/logout").finally(() => {
        store.commit("logout");
        router.push({ name: "Login", params: { locale: i18n.locale } });
        location.reload();
      });
    },
  },
  mounted() {
    const fullName = this.$store.state.fullName;
    const nameArr = fullName.split(" ");
    nameArr.forEach((str) => {
      if (str.length > 21) {
        this.nameFontSize = `${(1 / str.length) * 21}em`;
      }
    });
    if (fullName.length > 63) {
      let nfs = `${(1 / fullName.length) * 42}em`;
      if (nfs < this.nameFontSize) {
        this.nameFontSize = nfs;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.sidenav-wrp {
  width: 197px;
  height: 546px;
  margin: 0 8px 0 -12px;
  padding-left: 12px;
  border-radius: 12px;
  background: linear-gradient(0deg, #6a2545 0%, #b03b6f 100%);
  position: sticky;
  top: 104px;
  left: -12px;
}
.user-icon {
  width: 87px;
  height: 87px;
  margin: 36px 24px 0 24px;
}
.name-box {
  margin: 0 6px 0 6px;
  font-weight: 800;
  color: #fff;
  min-height: 44px;
  overflow: hidden;
  max-height: 66px;
}
.link-container {
  width: 221px;
  padding: 6px 0;
  background-color: #fff;
  border-radius: 12px;
  margin-left: -12px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.19);
  margin-top: 7px;
  .row {
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    margin-right: 12px;
    border-radius: 12px;
    color: #4a4a4a;
    opacity: 0.8;
    font-weight: 600;
  }
  .row:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
  .router-link-active {
    .row {
      color: #b03b6f;
      font-weight: 900;
    }
  }
  .divider {
    opacity: 0.1;
    border: solid 1px #000000;
    margin: 6px 12px 6px 24px;
  }
}
a {
  text-decoration: none;
}
.logout {
  display: flex;
  margin: 0 59px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  color: #eea757;
  position: absolute;
  bottom: 40px;
  .logout-icon {
    width: 19px;
    height: 19px;
    margin: 0 0 0 12px;
  }
}
@media (max-height: 660px) {
  .sidenav-wrp {
    height: 480px;
    top: 81px;
  }
  .user-icon {
    margin-top: 16px;
  }
  .logout {
    bottom: 16px;
  }
}
@media (max-height: 609px) {
  .sidenav-wrp {
    height: 320px;
  }
  .link-container {
    margin-top: 0;
  }
  .user-icon {
    display: none;
  }
  .name-box {
    display: none;
  }
}
@media (max-height: 400px) {
  .sidenav-wrp {
    height: 240px;
  }
  .sidenav-wrp{
    font-size: 0.9em;
  }
  .link-container {
    .row {
      height: 30px;
    }
    .divider {
    margin: 4px 24px 4px 12px;
  }
  }
  .logout {
    bottom: 10px;
  }
}
</style>
