<template>
  <div id="app">
    <Header v-if="this.$store.state.loggedIn"/>
    <div v-if="this.$store.state.loggedIn">
      <div class="main-wrp">
        <div class="side-menu">
          <SideMenu />
        </div>
        <div class="side-menu-mobile">
          <SideMenuMobile />
        </div>
        <div class="content-wrp">
          <router-view />
        </div>
      </div>
      <Footer />
    </div>
    <div v-if="!this.$store.state.loggedIn" class="main-wrp-login">
      <router-view />
    </div>
    <ModalIdle
      v-if="showIdleModal && this.$store.state.loggedIn"
      @idle-modal-closed="onIdleModalClosed"
    />
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap");
body {
  margin: 0;
}
#app {
  font-family: Nunito, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #7e7e7e;
  background-color: #343434;
}
.main-wrp {
  padding: 128px 0 0;
  background-color: #f3f3f3;
  display: flex;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
  @media (max-height: 609px) {
    padding-top: 82px;
  }
}
.main-wrp-login {
  padding: 0;
  background-color: #fff;
}
.content-wrp {
  display: flex;
  justify-content: center;
  padding-bottom: 139px;
  margin: auto;
  margin-top: 0;
  max-width: 1168px;
  width: 100%;
  min-height: 500px;
  @media (min-width: 1440px) {
    padding-right: 98px;
  }
  @media (min-width: 1600px) {
    padding-right: 197px;
  }
  @media (max-height: 660px) {
    min-height: 320px;
  }
}
.side-menu {
  @media (max-width: 768px) {
    display: none;
  }
}
.side-menu-mobile {
  @media (min-width: 769px) {
    display: none;
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideMenu from "@/components/SideMenu.vue";
import SideMenuMobile from "@/components/SideMenuMobile.vue";
import ModalIdle from "@/components/ModalIdle";
export default {
  components: {
    Header,
    Footer,
    SideMenu,
    SideMenuMobile,
    ModalIdle,
  },
  data() {
    return {
      showIdleModal: false,
    };
  },
  created() {
    setInterval(() => {
      if (!this.$store.state.idleVue.isIdle && this.$store.state.loggedIn) {
        this.$http.get("/session");
      }
    }, 300000);
  },
  methods: {
    onIdleModalClosed(value) {
      this.showIdleModal = !value;
    },
  },
  onIdle() {
    if (this.$store.state.loggedIn) {
      this.showIdleModal = true;
    }
  },
};
</script>
